import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply } from '@fortawesome/free-solid-svg-icons'

import Header from '../../../../components/Header'
import Footer from '../../../../components/Footer'
import ImageTextContainer from '../../../../components/ImageTextContainer'
import ImageText from '../../../../components/ImageTextContainer/ImageText'
import BannerTestimonie from '../../../../components/BannerTestimonie'
import BannerImageText from '../../../../components/BannerImageText'
import Button from '../../../../components/Button'

import './index.scss'

function EatWell() {
	const pageId = 22

	return (
		<div className="SOUKIASSIAN-EatWell">
			<Header
				type="finalPage"
				title="STAGE BIEN SE NOURRIR"
				subTitle="“Jeûnez en groupe et libérez vos plus grands blocages”"
			/>

			<div className="col-max-blocked center margined-y">
				<Button link="/stages">
					<FontAwesomeIcon icon={faReply} />
					{' REVENIR AUX STAGES'}
				</Button>
			</div>

			<ImageTextContainer>
				<ImageText
					pageId={pageId}
					entityId={1}
					flippedImage
					title="Jeûner en conscience"
					text={
						<>
							<span>Expérimentez le jeûne L.C.C !</span>
							<span>Vivons ensemble le jeûne libérateur.</span>
							<span>
								A l'encontre de vos frustrations inconscientes
								libérez-vous de vos schémas perturbateurs.
							</span>
							<span>
								<br />
								<b>Nous rejoindre</b>
								<br />
								<br />
								pour booster votre évolution et bien vous
								nourrir en conscience.
							</span>
						</>
					}
				/>
				<ImageText
					pageId={pageId}
					entityId={2}
					title={
						<>
							(2 jours)
							<br />
							Guidé par Christophe Soukiassian
						</>
					}
					text={
						<>
							<span>
								<b>Le jeûne L.C.C. ©</b>
								<br />
								“Libérez les conflits intérieurs.
								<br />
								Osez traverser vos freins inconscients.”
							</span>
							<span>
								<b>Communication avec les états internes</b>
								<br />
								Les participants traversent en conscience les
								conflits internes en transformations positives.
							</span>
							<span>
								<b>Renouvelez vos énergies</b>
								<br />
								Apprenez comment extérioriser naturellement les
								états de pensées néfastes et nourrissez-vous
								d'une nouvelle énergie.
							</span>
						</>
					}
					left
				/>
				<ImageText
					pageId={pageId}
					entityId={3}
					flippedImage
					title={
						<>
							Nous rejoindre dans ce stage
							<br />
							“Bien se nourrir”
						</>
					}
					textCenter
					text={
						<span>
							<Button link="/registration/stage/eat-well">
								S'INSCRIRE
							</Button>
						</span>
					}
				/>
			</ImageTextContainer>

			<BannerTestimonie
				pageId={pageId}
				entityId={4}
				name="Raphaël Meunier"
				commentary="Participer à ce stage a été une expérience de transformation. J'ai vécu des libérations émotionnelles qui m'ont ouvert des portes insoupçonnées vers une vie plus épanouie et alignée."
			/>

			<BannerImageText pageId={pageId} entityId={5}></BannerImageText>

			<Footer />
		</div>
	)
}

export default EatWell
