import Header from '../../../components/Header'
import BannerText from '../../../components/BannerText'
import HarmoniousTitle from '../../../components/HarmoniousTitle'
import Footer from '../../../components/Footer'
import ImageTextFilletPowder from '../../../components/ImageTextFilletPowder'
import ITFPChild from '../../../components/ImageTextFilletPowder/ITFPChild'

import './index.scss'

function Stages() {
	const pageId = 4

	return (
		<div className="SOUKIASSIAN-Stages">
			<Header
				type="intermediatePage"
				title="DÉCOUVREZ NOTRE SÉLECTION COMPLÈTES DE STAGES"
				pageId={pageId}
				entityId={1}
			/>

			<BannerText
				title={
					<>
						ÉVOLUONS ENSEMBLE
						<br />
						Lors de cinq stages exceptionnels.
					</>
				}
			/>

			<HarmoniousTitle title="LES STAGES D'ORIENTATION DE BIEN-ÊTRE ET BIEN-VIVRE">
				<ImageTextFilletPowder>
					<ITFPChild
						title="INITIATION CONSCIENCE"
						pageId={pageId}
						entityId={2}
						link="consciousness-initiation"
						text="Organisez et participez à ce stage pour approfondir votre compréhension de vous-même avec les autres."
						animate
					/>
					<ITFPChild
						title="SAGESSE"
						pageId={pageId}
						entityId={3}
						flippedImage
						link="sagacity"
						text="Venez acquérir la comportementale du sage en lien avec le monde qui vous entoure."
						animate
						right
					/>
					<ITFPChild
						title="MÉDITATION"
						pageId={pageId}
						entityId={4}
						flippedImage
						link="meditation"
						text="Plongez en conscience au cœur de vous-même et agissez positivement chaque jour."
						animate
					/>
					<ITFPChild
						title="BIEN SE NOURRIR"
						pageId={pageId}
						entityId={5}
						flippedImage
						link="eat-well"
						text="Expérimentez un jeûne de 2 jours et libérez vos plus grandes frustrations pour apprendre à mieux vous nourrir dans votre vie."
						animate
						right
					/>
					<ITFPChild
						title="SÉJOUR À L'ÉTRANGER"
						pageId={pageId}
						entityId={6}
						flippedImage
						link="foreign-trip"
						text="Profitez d'un séjour à l'étranger pour vivre une expérience transformatrice afin de vous ouvrir à de nouveaux horizons."
						animate
					/>
				</ImageTextFilletPowder>
			</HarmoniousTitle>

			<Footer />
		</div>
	)
}

export default Stages
